<template>
  <div>
    <v-dialog v-model="dialogServicoForm" v-if="dialogServicoForm" width="800px" max-width="800px">
        <v-card>
          <v-card-title>
            <span v-if="!servico.id" class="headline">{{ $t('label.adicionar_item') }}</span>
            <span class="headline" v-else>{{ $t('label.editar_item') }}</span>
          </v-card-title>
          <v-form ref="formModal" lazy-validation>
            <v-container grid-list-md>
              <v-row>
                <v-col cols="12">
                   <v-textarea
                      id="campo_tipo"
                      name="campo_tipo"
                      v-model="servico.tipo"
                      :label="`${$tc('label.servico_tipo_de_servico', 1)} *`"
                      :disabled="somenteLeitura"
                      :counter="100"
                      maxlength="100"
                      auto-grow
                      rows="1"
                      :rules="[rules.required]"
                      >
                    </v-textarea>
                </v-col>
                <v-col cols="12">
                   <v-textarea
                      id="campo_nome_detalhamento"
                      name="campo_nome_detalhamento"
                      v-model="servico.descricao"
                      :label="`${$tc('label.detalhamento', 1)} *`"
                      :disabled="somenteLeitura"
                      :counter="500"
                      maxlength="500"
                      auto-grow
                      rows="1"
                      :rules="[rules.required]"
                      >
                    </v-textarea>
                </v-col>
                <v-col cols="4" sm="4" md="4" slot="antes">
                  <v-text-field
                    id="conta_contabil"
                    v-model="servico.contacontabil"
                    :label="`${$tc('label.servico_terceiros_conta_contabil', 1)} *`"
                    :disabled="somenteLeitura"
                    :counter="20"
                    maxlength="20"
                    :rules="[rules.required]"
                    >
                  </v-text-field>
                </v-col>
                <v-col cols="4" sm="4" md="4" slot="antes">
                  <v-text-field
                    id="centro_custo"
                    v-model="servico.centrocusto"
                    :label="`${$tc('label.centro_custo', 1)} *`"
                    :disabled="somenteLeitura"
                    :counter="20"
                      maxlength="20"
                     :rules="[rules.required]"
                    >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="mt-4">
                  <input-money
                    :label="`${$tc('label.valor', 1)} * `"
                    dense
                    :disabled="somenteLeitura"
                    :rules="[rules.required, rules.valorPositivo]"
                    v-model="servico.vlrunitario">
                  </input-money>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text
                color="primary"
                @click="close">{{ $t('label.cancelar') }}</v-btn>
              <v-btn text
                color="primary"
                @click="adicionarServico" >{{ !isEdicao ? $t('label.adicionar') : $t('label.salvar') }}</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
  </div>
</template>
<script>
import InputMoney from '@/produto/shared-components/inputs/InputMoney';

export default {
  name: 'ServicosTerceirosModal',
  components: {
    InputMoney,
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
  },
  data() {
    return {
      servico: this.inicializaParametros(),
      dialogServicoForm: false,
      isEdicao: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorPositivo: (v) => (v && v > 0) || this.$t('errors.valor.maior_zero'),
      },
      itemCopy: {},

    };
  },
  methods: {
    open(item) {
      if (item) {
        const itemCopy = Object.assign([], item);
        this.servico = itemCopy;
        this.isEdicao = true;
      } else {
        this.servico = { vlrunitario: 0 };
      }
      this.dialogServicoForm = true;
    },
    close() {
      this.isEdicao = false;
      this.dialogServicoForm = false;
      this.inicializaParametros();
    },
    adicionarServico() {
      if (!this.$refs.formModal.validate()) return;
      this.dialogServicoForm = false;
      this.servico.quantidade = 1;
      this.servico.vlrtotalitem = this.servico.quantidade * this.servico.vlrunitario;

      this.$emit('SALVAR_SERVICO', this.servico);
      this.$emit('RECALCULAR_TOTAL');
      this.isEdicao = false;
    },
    selecionaTipo() {
      this.servico = this.inicializaParametros();
    },
    inicializaParametros() {
      return {
        ...this.servico,
        vlrunitario: 0.0,
      };
    },
  },
};
</script>
