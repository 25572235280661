<template>
   <v-btn
    class="v-btn__fab"
    color="accent"
    @click.native="openCadastroKpis()"
    >
    {{$t('label.kpis_consultar_kpis')}}
    </v-btn>
</template>
<script>

import { mapGetters } from 'vuex';
import { generateComputed } from '@/produto/common/functions/roles-computed-generator';

export default {
  name: 'PlanejamentoAcaoKpisSustentabilidade',
  data() {
    return {
      resourceKpis: this.$api.akzoKpis(this.$resource),
      item: null,
      idAcao: null,
    };
  },
  computed: {
    ...generateComputed('KPIS_SUSTENTA', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    podeNavegar() {
      return this.canAccessPage || this.canAccessCRUD;
    },
    hasRole() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'KPIS_SUSTENTA_CRUD').length;
    },
  },
  methods: {
    buscarKpisAcao(idAcao) {
      this.resourceKpis.buscarKpisAcao({ idAcao })
        .then((res) => {
          this.item = res.data;
        }).catch((err) => {
          this.$toast(err.data.error);
        });
    },
    openCadastroKpis() {
      if (!this.podeNavegar) {
        this.$toast(this.$t('message.kpis_nao_possui_acesso_ao_kpis'));
        return;
      }

      const id = this.item && this.item.idKpis ? this.item.idKpis : null;
      if (this.idAcao && id) {
        this.route = this.hasRole ? this.getRotaEdicao(id) : this.getRotaVisualizacao(id);
        window.open(this.route.href, '_blank');
      } else {
        this.$toast(this.$t('message.kpis_nao_possui_kpis_associado'));
      }
    },
    getRotaEdicao(id) {
      return this.$router.resolve({ name: 'editarKpis', params: { id, from: 'kpis' } });
    },
    getRotaVisualizacao(id) {
      return this.$router.resolve({ name: 'verKpis', params: { id, from: 'kpis' } });
    },
  },
  mounted() {
    this.idAcao = this.$route.params && this.$route.params.idAcao ? this.$route.params.idAcao : null;
    if (this.idAcao) {
      this.buscarKpisAcao(this.idAcao);
    }
  },
};
</script>
