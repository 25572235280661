<template>
  <div>
    <v-dialog v-model="dialogServicoForm" v-if="dialogServicoForm" width="800px" max-width="800px">
        <v-card>
          <v-card-title>
            <span v-if="!servico.id" class="headline">{{ $t('label.adicionar_item') }}</span>
            <span class="headline" v-else>{{ $t('label.editar_item') }}</span>
          </v-card-title>
          <v-form ref="formModal" lazy-validation>
            <v-container grid-list-md>
              <v-row>
                <v-col cols="8" sm="4">
                  <v-radio-group v-model="servico.tipo" @change="selecionaTipo()" :rules="[rules.required]" row>
                    <v-radio :label="$tc('label.produto', 1)" value="produto"></v-radio>
                    <v-radio :label="$tc('label.outro')" value="outro"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <v-autocomplete
                    id="brand"
                    dense
                    name="brand"
                    :label="`${$tc('label.brand', 1)} *`"
                    :items="listaBrand"
                    v-model="servico.brand"
                    clearable
                    item-text="nomExtensao"
                    :rules="[rules.produtoRequired]"
                    item-value="id"
                    return-object
                    :no-data-text="$tc('message.nenhum_registro', 1)"
                    :disabled="somenteLeitura || !isProdutoSelecionado"
                    @click.native="() => buscarBrand()">
                    <template slot="selection" slot-scope="data">
                      {{ data.item.idExterno }} - {{ data.item.nomExtensao }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.idExterno }} - {{ data.item.nomExtensao }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <input-decimal
                    id="volume"
                    v-model="servico.volume"
                    dense
                    :disabled="somenteLeitura || !isProdutoSelecionado"
                    :rules="[rules.produtoRequired, rules.valorPositivoProduto]"
                    :label="`${$tc('label.volume')} *`"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    id="detalhamento"
                    :label="`${$tc('label.detalhamento')} *`"
                    :rules="[rules.outroRequired]"
                    dense
                    :counter="500"
                    maxlength="500"
                    :disabled="somenteLeitura || !isOutroSelecionado"
                    v-model="servico.descricao" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <input-money
                    :label="`${$tc('label.valor', 1)} * `"
                    :rules="[rules.naoSelecionadoRequired, rules.valorPositivoTipoSelecionado]"
                    dense
                    :disabled="somenteLeitura || isTipoNaoSelecionado"
                    v-model="servico.vlrunitario">
                  </input-money>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text
                color="primary"
                @click="close">{{ $t('label.cancelar') }}</v-btn>
              <v-btn text
                color="primary"
                @click="adicionarServico" >{{ !isEdicao ? $t('label.adicionar') : $t('label.salvar') }}</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
  </div>
</template>
<script>
import InputMoney from '@/produto/shared-components/inputs/InputMoney';
import InputDecimal from '@/produto/shared-components/inputs/InputDecimal';

export default {
  name: 'PlanejamentoAcaoFormModalDetalhesAcao',
  components: {
    InputMoney,
    InputDecimal,
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isProdutoSelecionado() {
      return this.servico.tipo === 'produto';
    },
    isOutroSelecionado() {
      return this.servico.tipo === 'outro';
    },
    isTipoNaoSelecionado() {
      return !this.servico.tipo;
    },
  },
  data() {
    return {
      roiResource: this.$api.roi(this.$resource),

      servico: this.inicializaParametros(),
      dialogServicoForm: false,
      isEdicao: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorPositivo: (v) => (v && v > 0) || this.$t('errors.valor.maior_zero'),
        produtoRequired: (value) => this.rules.required(!this.isProdutoSelecionado || !!value),
        outroRequired: (value) => this.rules.required(!this.isOutroSelecionado || !!value),
        naoSelecionadoRequired: (value) => this.rules.required(this.isTipoNaoSelecionado || !!value),
        valorPositivoProduto: (value) => this.rules.valorPositivo(!this.isProdutoSelecionado || !!value),
        valorPositivoTipoSelecionado: (value) => this.rules.valorPositivo(this.isTipoNaoSelecionado || !!value),
      },
      listaBrand: [],
      itemCopy: {},

    };
  },
  methods: {
    open(item) {
      if (item) {
        const itemCopy = { ...item };
        this.servico = itemCopy;
        this.isEdicao = true;
        if (itemCopy.brand) {
          this.listaBrand = [itemCopy.brand];
        }
      } else {
        this.servico = { vlrunitario: 0 };
      }
      this.dialogServicoForm = true;
    },
    close() {
      this.isEdicao = false;
      this.dialogServicoForm = false;
      this.inicializaParametros();
    },
    adicionarServico() {
      if (!this.$refs.formModal.validate()) return;
      this.dialogServicoForm = false;
      if (!this.isEdicao) {
        if (this.servico.descricao) {
          this.servico.descricao = this.servico.descricao.trim();
        }
        if (this.servico.volume === 0) {
          this.servico.volume = null;
        }
      }

      this.servico.quantidade = 1;
      this.$emit('SALVAR_SERVICO', this.servico);
      this.$emit('RECALCULAR_TOTAL');
      this.isEdicao = false;
    },
    selecionaTipo() {
      this.servico = this.inicializaParametros();
    },
    buscarBrand(autocomplete) {
      const params = { autocomplete };
      this.roiResource.buscarBrand(params).then((res) => {
        this.listaBrand = [...res.data];
      });
    },
    inicializaParametros() {
      return {
        ...this.servico,
        vlrunitario: 0.0,
        brand: null,
        volume: 0.0,
        descricao: null,
      };
    },
  },
};
</script>
