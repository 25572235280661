import { buscarAcoesRelacionadas } from '../../../../common/resources/akzo-planejamento-acao-cadastro';

export default {
  props: {
    acao: {
      type: Object,
      default: {},
    },
  },
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
    buscarTotalInvestido() {
      return new Promise((resolve, reject) => {
        buscarAcoesRelacionadas(this.getParamsAcoesRelacionas(), this.$resource)
          .then((res) => {
            let total = 0;
            if (res && res.data && res.data.length > 0) {
              total = res.data.map((o) => (o.vlrInvestido + o.vlrSemComprometimentoVerba))
                .reduce((a, b) => a + b, 0);
            }
            resolve(total);
          }, reject)
          .catch((err) => {
            this.$toast(this.$t(err));
          });
      });
    },
    buscarAcoesRelacionadasAkzo() {
      return new Promise((resolve, reject) => {
        buscarAcoesRelacionadas(this.getParamsAcoesRelacionas(), this.$resource)
          .then((res) => {
            resolve(res.data);
          }, reject)
          .catch((err) => {
            this.$toast(this.$t(err));
          });
      });
    },
    getParamsAcoesRelacionas() {
      return {
        idAcao: this.getCodAcao(),
        idProjeto: this.getCodProjeto(),
        idCliente: this.getCodCliente(),
        status: this.statusAcao(),
        statusApuracao: this.statusApuracao(),
        idApuracao: this.getCodApuracao(),
      };
    },
    getCodAcao() {
      return this.acao && this.acao.idAcao ? this.acao.idAcao : null;
    },
    getCodCliente() {
      if (this.acao && this.acao.idCliente) {
        return this.acao.idCliente;
      }
      return this.acao && this.acao.cliente && this.acao.cliente.id ? this.acao.cliente.id : null;
    },
    getCodProjeto() {
      if (this.acao && this.acao.idProjeto) {
        return this.acao.idProjeto;
      }
      return this.acao && this.acao.projeto && this.acao.projeto.id ? this.acao.projeto.id : null;
    },
    statusAcao() {
      return this.acao && this.acao.status ? this.acao.status : null;
    },
    statusApuracao() {
      return this.acao && this.acao.statusApuracao ? this.acao.statusApuracao : null;
    },
    getCodApuracao() {
      return this.acao && this.acao.idApuracao ? this.acao.idApuracao : null;
    },

  },
};
