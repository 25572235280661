<template>
  <div>
    <v-dialog v-model="dialogServicoForm" v-if="dialogServicoForm" width="800px" max-width="800px">
        <v-card>
          <v-card-title>
            <span v-if="!servico.id" class="headline">{{ $t('label.adicionar_item') }}</span>
            <span class="headline" v-else>{{ $t('label.editar_item') }}</span>
          </v-card-title>
          <v-form ref="formModal" lazy-validation>
            <v-container grid-list-md>
              <v-row>
                <v-col cols="8">
                  <v-autocomplete
                    id="produto"
                    dense
                    name="produto"
                    :label="`${$tc('label.produto', 1)} *`"
                    :items="listaProduto"
                    v-model="servico.produto"
                    clearable
                    item-text="codNome"
                    :rules="[rules.required]"
                    item-value="id"
                    return-object
                    :no-data-text="$tc('message.nenhum_registro', 1)"
                    :disabled="somenteLeitura"
                    :search-input.sync="search"
                    :persistent-placeholder="true"
                    :placeholder="`${$tc('label.digite_texto_aqui')}`"
                    @click.native="() => buscarProduto()">
                    <template slot="selection" slot-scope="data">
                      {{ data.item.idExterno }} - {{ data.item.nomProduto }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.idExterno }} - {{ data.item.nomProduto }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <input-decimal
                    id="quantidade"
                    :precision="0"
                    :label="`${$tc('label.quantidade')} *`"
                    :rules="[rules.required, rules.valorPositivoInteiro]"
                    dense
                    v-model.number="servico.quantidade"
                    :disabled="somenteLeitura"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <input-money
                    :label="`${$tc('label.valor_unitario', 1)} * `"
                    dense
                    :disabled="somenteLeitura"
                    :rules="[rules.required, rules.valorPositivo]"
                    v-model="servico.vlrunitario">
                  </input-money>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    id="lote"
                    :label="`${$tc('label.lote')} *`"
                    :rules="[rules.required]"
                    dense
                    :counter="50"
                    maxlength="50"
                    :disabled="somenteLeitura"
                    v-model="servico.lote" />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    id="cor"
                    :label="`${$tc('label.cor')} *`"
                    :rules="[rules.required]"
                    dense
                    :counter="50"
                    maxlength="50"
                    :disabled="somenteLeitura"
                    v-model="servico.cor" />
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text
                color="primary"
                @click="close">{{ $t('label.cancelar') }}</v-btn>
              <v-btn text
                color="primary"
                @click="adicionarServico" >{{ !isEdicao ? $t('label.adicionar') : $t('label.salvar') }}</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
  </div>
</template>
<script>
import InputMoney from '@/produto/shared-components/inputs/InputMoney';
import InputDecimal from '@/produto/shared-components/inputs/InputDecimal';
import { buscaProdutos } from '../../../../common/resources/akzo-planejamento';

export default {
  name: 'PlanejamentoAcaoFormModalDetalhesAcao',
  components: {
    InputMoney,
    InputDecimal,
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
  },
  data() {
    return {
      servico: this.inicializaParametros(),
      dialogServicoForm: false,
      isEdicao: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorPositivoInteiro: (v) => (v && v > 0) || this.$t('errors.valor.maior_zero_inteiro'),
        valorPositivo: (v) => (v && v > 0) || this.$t('errors.valor.maior_zero'),
      },
      listaProduto: [],
      itemCopy: {},
      search: null,
    };
  },
  watch: {
    search(val) {
      if (val != null) {
        this.buscarProduto(val);
      }
    },
  },
  methods: {
    open(item) {
      if (item) {
        const itemCopy = { ...item };
        this.servico = itemCopy;
        this.isEdicao = true;
        if (itemCopy.produto) {
          this.listaProduto = [itemCopy.produto];
        }
      } else {
        this.servico = { vlrunitario: 0 };
      }
      this.dialogServicoForm = true;
    },
    close() {
      this.isEdicao = false;
      this.dialogServicoForm = false;
      this.inicializaParametros();
    },
    adicionarServico() {
      if (!this.$refs.formModal.validate()) return;
      this.dialogServicoForm = false;
      this.servico.tipo = 'produto';
      this.servico.volume = this.servico.produto.volume * this.servico.quantidade;

      this.$emit('SALVAR_SERVICO', this.servico);
      this.$emit('RECALCULAR_TOTAL');
      this.isEdicao = false;
    },
    selecionaTipo() {
      this.servico = this.inicializaParametros();
    },
    buscarProduto(autocomplete) {
      buscaProdutos({ autocomplete }, this.$resource)
        .then((res) => {
          this.listaProduto = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    inicializaParametros() {
      return {
        ...this.servico,
        vlrunitario: 0.0,
        produto: null,
      };
    },
  },
};
</script>
