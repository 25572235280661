<template lang="html">
    <v-dialog v-model="dialogAcoesRelacionadas" >
      <template v-slot:activator="{ on }" >
        <v-btn icon class="mx-0" v-on="on" @click.native="buscar()">
          <v-icon>info</v-icon>
        </v-btn>
      </template>
        <v-card>
          <v-card-title class="pb-0">
          <v-row >
            <span class="headline" >{{ $t('label.lista_acoes_relacionadas') }}</span>
              <v-col md="2" offset-md="1"  >
                  <input-money
                  :label="$t('label.valor_acao')"
                  disabled
                  v-model="vlrAcao" />
              </v-col>
              <v-col md="2" >
                  <input-money
                  :label="$t('label.vlr_investido')"
                  disabled
                  v-model="vlrInvestido" />
              </v-col>
              <v-col  md="3" >
                  <input-money
                  :label="$t('label.vlr_acoes_sem_comp_verba')"
                  disabled
                  v-model="vlrSemComprometimentoVerba" />
              </v-col>
            </v-row>
          </v-card-title>
          <v-container fluid >
            <v-row>
              <v-col md="4" class="pt-0">
                <span class="font-weight-medium">{{ `${$t('label.projeto')}: ` }} </span>
                <span style="font-size:14px">{{getNomProjeto()}}</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-10">
              <v-col md="5" class="pt-1 d-flex flex-row align-center">
                <span style="font-size:14px">{{ $t('label.exibir_acoes_relacionadas_kpi') }}</span>
                 <v-switch
                  color="primary"
                  v-model="indKpi"
                  :disabled="false"
                  hide-details
                  class="mt-0 ml-10">
                </v-switch>
              </v-col>
            </v-row>
            <v-data-table
               class="mb-10"
              :headers="cabecalho"
              :items="listaAcoesRelacionadas"
              :footer-props="{
                itemsPerPageOptions: [10, 25, 50],
              }"
              :no-data-text="$t('label.tabela_sem_conteudo')"
              dense>

              <template v-slot:item.acoes="{ item }">
                <v-btn
                  @click="detalheItem(item)"
                  icon>
                  <v-icon>visibility</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.codKpi="{ item }">
                {{ item.codKpi }}
              </template>

              <template v-slot:item.tipo="{ item }">
                {{ item.tipo }}
              </template>

              <template v-slot:item.codPlanejamento="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on"> {{ item.codPlanejamento }}</span>
                  </template>
                  <span >{{ getStatusAcao(item.statusPlanejamento) }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.apuracoes="{ item }">
               <span v-for="(apu, index) in item.apuracoes" :key="index" v-if="item.apuracoes.length > 0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"> {{ getCodPagamentoAndApuracao(apu, index, item.apuracoes.length) }}</span>
                    </template>
                    <span >{{ getStatus(apu.status,apu.id) }}</span>
                  </v-tooltip>
               </span>
              </template>

              <template v-slot:item.pagamentos="{ item }">
               <span v-for="(pag, index) in item.pagamentos" :key="index" v-if="item.pagamentos.length > 0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"> {{ getCodPagamentoAndApuracao(pag, index, item.pagamentos.length) }}</span>
                    </template>
                    <span >{{ getStatus(pag.status, pag.id) }}</span>
                  </v-tooltip>
               </span>
              </template>

              <template v-slot:item.nomCliente="{ item }">
                {{ item.nomCliente }}
              </template>

              <template v-slot:item.dtaInicio="{ item }">
                {{ item.dtaInicio }}
              </template>

              <template v-slot:item.dtaFim="{ item }">
                {{ item.dtaFim }}
              </template>

              <template v-slot:item.vlrAcao="{ item }">
                {{ getMoney(item.vlrAcao) }}
              </template>

            </v-data-table>
          </v-container>
        </v-card>
    </v-dialog>
</template>
<script>

import InputMoney from '@/produto/shared-components/inputs/InputMoney';
import InputDecimal from '@/produto/shared-components/inputs/InputDecimal';
import { getMoney } from '../../../produto/common/functions/helpers';
import Status from '../../../produto/shared-components/Status';
import PlanejamentoAcaoAcoesRelacionadasFuncoes from './detalhe-acao/PlanejamentoAcaoAcoesRelacionadasFuncoes';

export default {
  name: 'PlanejamentoAcaoFormAcoesRelacionadasModal',
  mixins: [PlanejamentoAcaoAcoesRelacionadasFuncoes],
  components: {
    InputMoney,
    InputDecimal,
    Status,
  },
  props: {
    acao: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      dialogAcoesRelacionadas: false,
      vlrInvestido: 0,
      vlrSemComprometimentoVerba: 0,
      vlrAcao: 0,
      indKpi: false,
      acoesRelacionadas: [],
      cabecalho: [
        {
          text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
        {
          text: this.$tc('label.cod_kpi_acao_akzo', 1), value: 'codKpi', sortable: true,
        },
        {
          text: this.$tc('label.tipo_acao', 1), value: 'tipo', sortable: true,
        },
        {
          text: this.$tc('label.cod_planejamento_acao_akzo', 1), value: 'codPlanejamento', sortable: true, align: 'center',
        },
        {
          text: this.$tc('label.cod_apuracao', 1), value: 'apuracoes', sortable: true, align: 'center',
        },
        {
          text: this.$tc('label.cod_pagamento', 1), value: 'pagamentos', sortable: true, align: 'center',
        },
        {
          text: this.$tc('label.cliente_do_focao_acao', 1), value: 'nomCliente', sortable: true,
        },
        {
          text: this.$tc('label.data_inicio', 1), value: 'dtaInicio', sortable: true,
        },
        {
          text: this.$tc('label.data_fim', 1), value: 'dtaFim', sortable: true,
        },
        {
          text: this.$tc('label.valor', 1), value: 'vlrAcao', sortable: false,
        },
      ],
    };
  },
  computed: {
    indHabilitaModal() {
      return this.codProjeto !== null || this.codCliente !== null;
    },
    listaAcoesRelacionadas() {
      return this.indKpi ? this.acoesRelacionadas.filter((item) => item.codKpi && item.indMesmoKpi) : this.acoesRelacionadas;
    },
  },
  methods: {
    getMoney,
    getCodPagamentoAndApuracao(item, index, tamanho) {
      return index + 1 === tamanho ? item.id : `${item.id}, `;
    },
    detalheItem(item) {
      const idAcao = item.codPlanejamento;
      this.route = this.$router.resolve({ name: 'detalharAcao', params: { idAcao, from: 'planejamento-acao' } });
      window.open(this.route.href, '_blank');
    },
    getTotais() {
      this.vlrInvestido = this.acoesRelacionadas.map((c) => c.vlrInvestido)
        .reduce((p, c) => p + c, 0);

      this.vlrSemComprometimentoVerba = this.acoesRelacionadas.map((c) => c.vlrSemComprometimentoVerba)
        .reduce((p, c) => p + c, 0);
    },
    buscar() {
      this.getVlrAcao();
      this.acoesRelacionadas = [];
      this.vlrInvestido = 0;
      this.vlrSemComprometimentoVerba = 0;
      this.buscarAcoesRelacionadasAkzo()
        .then((res) => {
          this.acoesRelacionadas = res;
          if (this.acoesRelacionadas.length > 0) {
            this.getTotais();
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    getNomProjeto() {
      if (this.acao && this.acao.idApuracao && this.acao.projeto) {
        return this.acao.projeto;
      } if (this.acao && this.acao.projeto && this.acao.projeto.nomProjeto) {
        return this.acao.projeto.nomProjeto;
      } if (this.acao && this.acao.nomProjeto) {
        return this.acao.nomProjeto;
      }
      return this.$tc('label.nenhum_projeto_selecionado');
    },
    getVlrAcao() {
      this.vlrAcao = this.acao && this.acao.valor ? this.acao.valor : 0;
    },

    getStatusAcao(status) {
      let msg = '';
      switch (status) {
        case 'EM_CADASTRO':
          msg = this.$tc('label.em_cadastro');
          break;
        case 'AGUARDANDO_APROVACAO':
          msg = this.$tc('label.aguardando_aprovacao', 1);
          break;
        case 'APROVADO':
          msg = this.$tc('label.aprovado', 1);
          break;
        case 'EM_ANALISE':
          msg = this.$tc('label.em_analise', 1);
          break;
        case 'CANCELADO':
          msg = this.$tc('label.cancelado', 1);
          break;
        case 'REPROVADO':
          msg = this.$tc('label.reprovado', 1);
          break;
        default:
          return '';
      }
      return `${this.$t('label.status')}: ${msg}`;
    },

    getStatus(status, codigo) {
      let msg = '';
      switch (status) {
        case 'AGUARDANDO_APROVACAO':
          msg = this.$tc('label.aguardando_aprovacao', 1);
          break;
        case 'AGUARDANDO_APURACAO':
          msg = this.$tc('label.aguardando_apuracao', 1);
          break;
        case 'APROVADO':
          msg = this.$tc('label.aprovado', 1);
          break;
        case 'APURACAO_PREVIA':
          msg = this.$tc('label.apuracao_previa', 1);
          break;
        case 'APURADO':
          msg = this.$tc('label.apurado', 1);
          break;
        case 'CANCELADO':
          msg = this.$tc('label.cancelado', 1);
          break;
        case 'EM_ANALISE':
          msg = this.$tc('label.em_analise', 1);
          break;
        case 'REPROVADO':
          msg = this.$tc('label.reprovado', 1);
          break;
        case 'VENCIDO':
          msg = this.$tc('label.vencido', 1);
          break;
        case 'EM_CADASTRO':
          msg = this.$tc('label.em_cadastro', 1);
          break;
        case 'BLOQUEADO':
          msg = this.$tc('label.bloqueado', 1);
          break;
        case 'LIQUIDADO':
          msg = this.$tc('label.liquidado', 1);
          break;
        case 'AGUARDANDO_PAGAMENTO':
          msg = this.$tc('label.aguardando_pagamento', 1);
          break;
        default:
          return '';
      }
      return `${codigo} - ${msg}`;
    },
  },
};
</script>
