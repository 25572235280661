<template>
  <div style="padding: 18px;">
    <v-row class="PlanejamentoAcaoServicosTerceiros__Valores">
      <v-col cols="2">
        <input-money
          :label="$t('label.total_itens')"
          disabled
          v-model="totalItens">
        </input-money>
      </v-col>

      <v-col cols="2">
        <input-money
          :label="$t('label.valor_total_investido')"
          disabled
          v-model="totalInvestido">
        </input-money>
      </v-col>
      <div class="ml-n5 mt-6">
        <planejamento-acao-form-acoes-relacionadas-modal
            v-if="acao && acao.projeto || acao.cliente"
            :acao="acao" >
        </planejamento-acao-form-acoes-relacionadas-modal>
      </div>
    </v-row>

    <v-data-table
      :headers="listaCamposCabecalhos"
      :items="servicos"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 20, 30],
      }"
      dense>
      <template v-slot:item="{ item, index }">
          <tr>
            <td class="justify-center px-0">
              <v-tooltip bottom v-if="exibeAcoesItem(item)">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on" @click.stop="editar(item)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('label.editar') }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="exibeAcoesItem(item)">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on" @click="excluir(item)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('label.excluir') }}</span>
              </v-tooltip>
            </td>
            <td>{{item.tipo}}</td>
            <td>{{item.descricao}}</td>
             <td>{{item.fornecedor}}</td>
            <td>{{ item.vlrunitario ? getMoney(item.vlrunitario) : '' }}</td>
            <td v-if="isApuracao">
                <v-radio-group v-model="item.indconfirmado" row :disabled="!podeAdicionarItem" @change="recalcularValorAcao">
                  <v-radio :value="true">
                    <template v-slot:label>
                      <div :style="item.indconfirmado != null ? 'color: blue;' : ''">{{ $t('label.sim') }}</div>
                  </template>
                  </v-radio>
                  <v-radio :value="false">
                    <template v-slot:label>
                      <div :style="item.indconfirmado != null ? 'color: red;' : ''">{{ $t('label.nao') }}</div>
                  </template>
                  </v-radio>
                </v-radio-group>
            </td>
          </tr>
      </template>
    </v-data-table>
    <v-row>
      <v-col cols="5"  class="pb-0">
        <v-text-field
          id="localEntrega"
          :rules="[rules.required]"
          required
          v-model.trim="localEntrega"
          @input="alteracaoEntrega"
          maxlength="50"
          counter="50"
          :disabled="somenteLeitura || isApuracao"
          :label="`${$tc('label.local_entrega', 1)} *`"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5" class="pb-2">
        <v-text-field
          id="enderecoEntrega"
          v-model.trim="enderecoEntrega"
          :rules="[rules.required]"
          required
          maxlength="500"
          counter="500"
          @input="alteracaoEntrega"
          :disabled="somenteLeitura || isApuracao"
          :label="`${$tc('label.endereco_entrega', 1)} *`"/>
      </v-col>
      <v-col cols="6" class="d-flex justify-end mt-4">
        <planejamento-acao-kpis-sustentabilidade>
        </planejamento-acao-kpis-sustentabilidade>
      </v-col>
      <v-col style="text-align: end;">
        <v-btn fab
          bottom right
          class="v-btn__fab"
          color="primary"
          @click.native="abrirModalServico()"
          v-if="podeAdicionarItem">
          <v-icon>add</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <confirm
      ref="confirmDialog"
      :persistent="true">
    </confirm>
    <comunicacao-acao-modal
      ref="modalFormServico"
      :somenteLeitura="somenteLeitura"
      item="itemSelecionado"
      @SALVAR_SERVICO="adicionar"
      @RECALCULAR_TOTAL="recalcularValorAcao"/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { generateComputed } from '@/produto/common/functions/roles-computed-generator';
import { getMoney, getNumber } from '@/produto/common/functions/helpers';
import Confirm from '@/produto/shared-components/vuetify/dialog/Confirm';
import InputMoney from '@/produto/shared-components/inputs/InputMoney';
import resourcePlanejamentoAcaoServico from '@/produto/common/resources/planejamento/planejamento-acao-servico';
import MetadadosContainerLayout from '@/produto/shared-components/metadados/MetadadosContainerLayout';
import ComunicacaoAcaoModal from './ComunicacaoAcaoModal';
import PlanejamentoAcaoFormAcoesRelacionadasModal from '../PlanejamentoAcaoFormAcoesRelacionadasModal';
import PlanejamentoAcaoAcoesRelacionadasFuncoes from './PlanejamentoAcaoAcoesRelacionadasFuncoes';
import PlanejamentoAcaoKpisSustentabilidade from './PlanejamentoAcaoKpisSustentabilidade';

export default {
  name: 'PlanejamentoAcaoComunicacao',
  mixins: [PlanejamentoAcaoAcoesRelacionadasFuncoes],
  props: {
    acao: {
      type: Object,
      default: {},
    },
    tipoAcao: {
      type: Object,
      required: true,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    novaAcao: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
    isApuracao: {
      type: Boolean,
      default: false,
    },
    editaApuracao: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Confirm,
    InputMoney,
    MetadadosContainerLayout,
    ComunicacaoAcaoModal,
    PlanejamentoAcaoFormAcoesRelacionadasModal,
    PlanejamentoAcaoKpisSustentabilidade,
  },
  watch: {
    acao: {
      handler(acao) {
        if ((acao.projeto && acao.projeto.id !== this.projetoSelecionado.id)
          || (acao.cliente && acao.cliente.id !== this.clienteSelecionado.id)
          || (this.projetoSelecionado.id && !acao.projeto)) {
          this.obtemTotalInvestido();
        } else if (!acao.cliente && !acao.projeto) {
          this.totalInvestido = 0;
        }

        this.clienteSelecionado = { ...acao.cliente };
        this.projetoSelecionado = { ...acao.projeto };

        this.servicos.forEach((s) => {
          s.vlrTotalInvestido = this.totalInvestido;
        });
      },
      deep: true,
    },
  },
  data() {
    return {
      planejamentoAcaoServicoResources: resourcePlanejamentoAcaoServico(this.$resource),
      planejamentoAcaoResourceAkzo: this.$api.akzoPlanejamentoAcaoCadastro(this.$resource),

      servicos: [],
      cabecalho: [
        {
          text: this.$tc('label.acoes', 2), value: 'acoes', width: '6%', sortable: false,
        },
        {
          text: this.$tc('label.servico_tipo_de_servico', 1), value: 'tipo', sortable: true,
        },
        {
          text: this.$tc('label.detalhamento', 1), value: 'detalhamento', sortable: false,
        },
        {
          text: this.$tc('label.fornecedor', 1), value: 'centroCusto', sortable: false,
        },
        {
          text: this.$tc('label.valor', 1), value: 'valortotal', sortable: false,
        },
      ],
      localEntrega: null,
      enderecoEntrega: null,
      camposDinamicos: [
        { labelCampo: 'tipo_instituicao', obrigatorio: true },
      ],
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: true,
        md12: true,
        sm6: false,
        md3: false,
        md4: false,
        md6: false,
        md9: false,
      },
      metadadosParametrizado: {},
      camposFormulario: {
        padrao: [],
      },
      instituicao: null,
      clientes: [],
      totalItens: 0,
      itemSelecionado: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      clienteSelecionado: {},
      totalInvestido: undefined,
      projetoSelecionado: {},
    };
  },
  computed: {
    ...generateComputed('ACAO', [
      'canEdit',
    ]),
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getProdutoMetadado',
    ]),
    isStatusEmAnaliseOuEmCadastro() {
      return this.acao.status === 'Em cadastro'
        || this.acao.status === 'Em análise';
    },
    possuiServicoAdicionadoNaoSalvo() {
      return this.servicos.filter((s) => !s.id).length > 0;
    },
    listaCamposCabecalhos() {
      const campos = this.cabecalho;

      if (this.isApuracao) {
        campos.push({
          text: this.$tc('label.item_confirmado', 1), width: '15%', value: 'indconfirmado', sortable: false,
        });
      }

      return campos;
    },
    podeAdicionarItem() {
      return this.isApuracao ? this.editaApuracao : this.canEdit && this.isStatusEmAnaliseOuEmCadastro && !this.somenteLeitura;
    },
  },
  methods: {
    getMoney,
    getNumber,
    buscar() {
      if (!this.novaAcao) {
        this.obtemTotalInvestido();
        const params = {
          idAcao: this.$route.params.idAcao || this.acao.idAcao,
          page: 1,
          size: 50000,
        };
        this.planejamentoAcaoServicoResources.buscar(params)
          .then((res) => {
            const { resposta } = res.data;
            this.servicos = resposta.filter((item) => this.isApuracao || !item.inditemapuracao);
            this.recalcularValorAcao();
            const s = this.servicos[0];
            if (s) {
              this.localEntrega = s.localentrega;
              this.enderecoEntrega = s.enderecoentrega;
            }
            this.servicos.forEach((acao) => {
              if (acao) {
                acao.vlrTotal = acao.quantidade * acao.vlrunitario;
              }
              acao.isSustentabilidade = true;
            });
          }, (err) => {
            this.$error(this, err);
            this.$toast(this.$t(err));
          });
      }
    },
    adicionar(servico) {
      const servicoEditado = { ...servico };
      servicoEditado.inditemapuracao = this.isApuracao;
      if (this.itemSelecionado != null) {
        this.servicos.splice(this.itemSelecionado, 1, servicoEditado);
      } else {
        this.servicos.push(servicoEditado);
      }

      if (servicoEditado.id) {
        servicoEditado.isSustentabilidade = true;
        this.salvarDetalhes(servicoEditado);
      }

      this.recalcularValorAcao();
      this.servicos.forEach((s) => {
        s.vlrtotalinvestido = this.totalInvestido;
        s.localentrega = this.localEntrega;
        s.enderecoentrega = this.enderecoEntrega;
        s.instituicao = this.instituicao ? this.instituicao.id : null;
        s.isSustentabilidade = true;
      });
      this.totalPage = this.servicos.length;
    },
    salvarDetalhes(acaoDetalhes) {
      return this.planejamentoAcaoResourceAkzo.atualizaDetalhes(acaoDetalhes)
        .then(() => {
          this.$toast(this.$t('message.atualizar_tabela'));
        });
    },
    salvarDetalhesApuracao() {
      return this.planejamentoAcaoResourceAkzo.atualizaDetalhesApuracao({ idAcao: this.acao.idAcao }, this.servicos)
        .then(() => {
          this.buscar();
          this.$toast(this.$t('message.atualizar_tabela'));
        });
    },
    excluir(item) {
      if (!this.novaAcao && item.id) {
        this.planejamentoAcaoServicoResources.deletar({ id: item.id })
          .then(() => {
          }, (err) => {
            this.$error(this, err);
            this.$toast(this.$t(err));
          });
      }
      const idx = this.servicos.indexOf(item);
      this.servicos.splice(idx, 1);
      this.recalcularValorAcao();
    },
    editar(item) {
      this.itemSelecionado = this.servicos.indexOf(item);
      this.$refs.modalFormServico.open(item);
    },
    abrirModalServico() {
      this.itemSelecionado = null;
      this.$refs.modalFormServico.open();
    },
    calcularTotal(item) {
      return this.getMoney(item.volume * item.valor);
    },
    recalcularValorAcao() {
      if (this.isApuracao) {
        this.acao.valor = this.servicos.map((item) => (item.indconfirmado === false ? 0 : item.vlrunitario))
          .reduce((prev, next) => prev + next);
      } else if (!this.servicos.length) {
        this.acao.valor = 0;
      } else {
        this.acao.valor = this.servicos.map((item) => item.vlrunitario)
          .reduce((prev, next) => prev + next);
      }

      this.totalItens = this.acao.valor;
      this.alteraValorAcao();
    },
    alteraValorAcao() {
      this.$emit('PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao', this.acao.valor);
    },
    getServicos() {
      return this.servicos;
    },
    alteracaoEntrega() {
      this.servicos.forEach((s) => {
        s.localentrega = this.localEntrega;
        s.enderecoentrega = this.enderecoEntrega;
      });
    },
    validaSecaoDetalheAcao() {
      if (!this.servicos.length) {
        this.$toast(this.$t('errors.adicione_um_servico'));
      }
      return this.servicos.length > 0;
    },
    aplicaParametrizacoes() {
      const camposCustomizados = this.filtraCamposCustomizados();
      this.metadadosParametrizado = this.configuracao.aplicaParametrizacao(camposCustomizados);
    },
    filtraCamposCustomizados() {
      const campos = {};
      const { mapaCamposDinamicos } = this.getAcaoMetadado;
      this.camposDinamicos.forEach((cd) => {
        campos[cd.labelCampo] = mapaCamposDinamicos[cd.labelCampo];
      });

      return {
        cabecalho: this.getAcaoMetadado,
        entidade: this.getAcaoMetadado.entidade,
        mapaCamposDinamicos: campos,
      };
    },
    exibeAcoesItem(item) {
      return this.isApuracao ? item.inditemapuracao && this.editaApuracao : this.canEdit && !this.somenteLeitura;
    },
    obtemTotalInvestido() {
      if (this.getCodProjeto() != null || this.getCodCliente() != null) {
        this.buscarTotalInvestido().then((response) => {
          this.totalInvestido = response;
        });
      }
    },
  },
  mounted() {
    if (!this.novaAcao) {
      this.buscar();
    }
    this.aplicaParametrizacoes();
  },
};
</script>
<style>
.PlanejamentoAcaoServicosTerceiros__Valores {
  text-align:end;
  padding-top:0px;
  padding-bottom:0px;
  padding-left:0px;
}
</style>
;
